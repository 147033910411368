import React from 'react';
import { SecondaryButton } from '@components/button';

// styles
import styled from 'styled-components';
import { Container, theme, media } from '@styles';

const { colors, fonts } = theme;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 60vh;
  margin: 3rem 0;
`;

const Styled404 = styled.h2`
  color: ${colors.accent};
  font-family: ${fonts.Primary};
  font-size: 300px;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 0.8;
  margin-bottom: 12px;
  position: relative;
  text-indent: -0.05em;

  ${media.tablet`font-size: 250px;`};
  ${media.phablet`font-size: 200px;`};
  ${media.phone`font-size: 158px;`};
`;

const StyledSub = styled.p`
  color: ${colors.text};
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 30px;
  text-transform: none;
  font-size: 40px;
  letter-spacing: 2px;
  font-family: ${fonts.Secondary};
  ${media.tablet`font-size: 30px;`};
`;

const StyledButton = styled(SecondaryButton)`
  width: 100%;
`;

export default () => {
  return (
    <Wrapper>
      <Container normal>
        <Styled404>404</Styled404>
        <StyledSub>Sorry, we couldn't find this page.</StyledSub>
        <StyledButton to="/">Go back home</StyledButton>
      </Container>
    </Wrapper>
  );
};
